<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        طباعه
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.go(-1)"
        />
      </template>
    </Toolbar>
    <div class="form-card row" id="print">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="name" class="form-label">
            تاريخ المعامله
          </label>
          {{ body.date }}
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="warehouseId" class="form-label">
            المستودع
          </label>

          <select
            class="form-select form-control"
            id="warehouseId"
            name="warehouseId"
            v-model.number="body.warehouseId"
            :class="{ 'is-invalid': isInvalid && !body.warehouseId }"
            required
            disabled
          >
            <option
              v-for="item of warehouseList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="mb-3 text-right" style="direction:ltr">
          <label for="code" class="form-label text-right required">
            <span>*</span>
            قيمه المعامله
          </label>

          <input class="form-control" v-model.number="body.price" disabled />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="taxesId" class="form-label required">
            الضريبه
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="taxesId"
            name="taxesId"
            v-model="body.taxesId"
            :class="{ 'is-invalid': isInvalid && !body.taxesId }"
            required
          >
            <option v-for="item of taxesList" :key="item.id" :value="item">{{
              item.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="total" class="form-label">
            الاجمالى
          </label>
          <input class="form-control" v-model.number="body.total" disabled />
        </div>
      </div> -->
      <div class="col-md-12" v-if="body.note">
        <div class="mb-3 ">
          <label for="note" class="form-label">
            الملاحظات
          </label>
          <textarea
            class="form-control"
            type="note"
            id="note"
            disabled
            v-model="body.note"
          />
        </div>
      </div>
      <div class="col-md-12">
        <DataTable
          ref="dt"
          :value="body.storeItemsList"
          class="p-datatable-customers table table-striped"
        >
          <template #header>
            <div class="table-header">
              قائمه المنتجات
            </div>
          </template>
          <template #empty>
            لا يوجد بيانات
          </template>

          <Column field="storeItemsId.name" header="المنتج" />
          <!-- <Column field="quantity" header="السعر" /> -->
          <Column field="quantity" header="الكميه" />
          <!-- <Column field="price" header="المجموع"> -->
          <!-- <template #body="slotProps">
              {{ slotProps.data.price * slotProps.data.quantity }}
            </template>
          </Column> -->

          <!-- <Column field="id" bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-trash" v-tooltip="'حذف'"
                @click="deleteItem(slotProps.index, slotProps.data)"
                class="p-button-warning  p-button-rounded"
              ></Button>
            </template>
          </Column> -->
        </DataTable>
        <!-- <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus" v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: new Date(),
        storeItemsList: [],
        taxesId: null,
        warehouseId: null,
        note: null,
        // price: 0,
        // total: 0,
      },
      productsAdd: {
        storeItemsId: null,
        quantity: 1,
        // price: 1,
      },
      id: null,
      isInvalid: false,
      disabledAdd: false,
      displayProductsAdd: false,
      storeItemsList: [],
      warehouseList: [],
      taxesList: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
   const user = JSON.parse(localStorage.admin);

     this.$http.get(`warehouse`).then((res) => {
      for (const item of res.data) {
      }
      this.warehouseList = res.data.filter((itm) =>
        itm.users.find((u) => u.id == user.id),
      );
    });
    
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`inventoryOpeningBalance/${this.id}`).then(
        async (res) => {
          this.body = await res.data;
          this.body.warehouseId = await res.data.warehouseId?.id;
          this.body.storeItemsList = await JSON.parse(res.data.storeItemsList);
          await setTimeout(()=>{

             this.printPage();
          },3000)
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
